body {
  font-family: "-apple-system",BlinkMacSystemFont,"Segoe UI",Arial,sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

@media (max-width: 1023px) {

  html, body {

    font-size: 14px;

  }

}

@media (max-width: 767px) {

  html, body {

    font-size: 13px;

  }

}

@media (max-width: 374px) {

  html, body {

    font-size: 12px;

  }

}

.app {
  padding: 0 20px 75px 20px;
}

.app-container {
  padding: 0;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
  width: calc(100% - 2px);
  max-width: 700px;
}

.table th, .table td{
  border: 1px solid #ddd;
  padding: 0.25em 0.5em;
  text-align: left;
  white-space: nowrap;
}

.table th{
  position: relative;
  background-color: #fff;
  cursor: pointer;
  border: none;
  border-left: 1px solid #ddd;
  padding-left: 20px;
  padding-top: 0;
  font-weight: 600;
}

.table th::after{
  content: "";
  left: 6px;
  top: 50%;
  margin-top: -7px;
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #d6d6d6 transparent;
}

.table th::before{
  content: "";
  left: 6px;
  top: 50%;
  margin-top: 1px;
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #d6d6d6 transparent transparent transparent;
}

.table th.sort.asc::after{
  border-color: transparent transparent #2196f3 transparent;
}

.table th.sort.desc::before{
  border-color: #2196f3 transparent transparent transparent;
}

.table tr:nth-child(2n) td{
  background-color: #f9f9f9;
}

.table td.sort{
  background-color: #f9f9f9;
}

.table tr:nth-child(2n) td.sort{
  background-color: #f1f1f1;
}


.filter {
  position: relative;
  max-width: 350px;
  padding: 1em 0;
}

.filter input{
  padding: 6px 24px 6px 4px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #bbb;
  outline: none;
}

.filter input:focus{
  border-color: #2196f3;
}

.filter button{
  position: absolute;
  top: 50%;
  right: 3px;
  margin-top: -8px;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  line-height: 1;
  cursor: pointer;
}

.pagination {
  display: flex;
  padding: 0 0 1em 0;
}

.pagination > span{
  display: flex;
  width: 30px;
  height: 30px;
  background: #f7f7f7;
  margin: 0 3px 0 3px;
  cursor: pointer;
  color: #666;
  font-weight: 700;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.pagination > span:first-child{
  margin-left: 0;
}

.pagination > span:last-child{
  margin-right: 0;
}

.pagination > span.sep{
  color: #bbb;
  cursor: default;
  font-weight: 400;
  background: none;
}

.pagination > span.item:hover{
  color: #f00;
}

@media (hover: none) {
  .pagination > span.item:hover{
    color: initial;
  }
}

.pagination > span.item.current{
  color: #333;
  cursor: default;
  background: #ffeba0;
}

@media (max-width: 374px) {

  .pagination > span{
    width: 24px;
    height: 24px;
    font-size: 14px;
  }

}

.no-results {
  padding: 1em 1em 1.125em 1em;
  background-color: #ffeba0;
  line-height: 1.5;
  text-align: center;
  max-width: 800px;
}

.loading {
  padding: 1em 1em 1.125em 1em;
  background-color: #ffeba0;
  line-height: 1.5;
  text-align: center;
  max-width: 800px;
}